import * as React from 'react';

import { cn } from '~/utils/tw';
import { Tooltip } from './tooltip';
import { Merge } from 'type-fest';

interface IconProps {
  size: number;
  className: string;
}

export type InputIconProps = {
  startIcon?: {
    icon: React.ReactElement<IconProps>;
    tooltip?: string | React.ReactNode;
  };
  endIcon?: {
    icon: React.ReactElement<IconProps>;
    tooltip?: string | React.ReactNode;
  };
};

export type InputProps = Merge<
  React.InputHTMLAttributes<HTMLInputElement>,
  InputIconProps
>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, startIcon, endIcon, ...props }, ref) => {
    return (
      <div className="group relative flex w-full flex-col items-start">
        <div className="relative w-full">
          {startIcon && (
            <Tooltip>
              <Tooltip.Trigger
                type="button"
                className="absolute left-4 flex h-full items-center"
              >
                {React.cloneElement(startIcon.icon, {
                  size: 16,
                  className: 'text-homy-gray',
                })}
              </Tooltip.Trigger>
              <Tooltip.Content
                className={cn('', {
                  hidden: !startIcon?.tooltip,
                })}
              >
                <Tooltip.Arrow />
                {startIcon.tooltip}
              </Tooltip.Content>
            </Tooltip>
          )}
          {endIcon && (
            <Tooltip>
              <Tooltip.Trigger
                type="button"
                className="absolute right-4 flex h-full items-center"
              >
                {React.cloneElement(endIcon.icon, {
                  size: 16,
                  className: 'text-homy-gray',
                })}
              </Tooltip.Trigger>
              <Tooltip.Content
                className={cn('', {
                  hidden: !endIcon?.tooltip,
                })}
              >
                <Tooltip.Arrow />
                {endIcon.tooltip}
              </Tooltip.Content>
            </Tooltip>
          )}
          <input
            type={type}
            className={cn(
              'flex h-10 w-full rounded-sm border border-input bg-background px-4 py-2 text-sm text-homy-gray-darker ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-homy-gray-light hover:shadow-sm focus:border-homy-gray-darker/50 focus-visible:outline-none active:border-homy-gray-light disabled:cursor-not-allowed',
              className,
              {
                'pl-12': startIcon,
              },
            )}
            ref={ref}
            {...props}
          />
        </div>
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
